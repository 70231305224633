import { z } from 'zod';
import { SaleDataType } from '../../../types';

export type OngoingSaleFormSchema = z.infer<typeof ONGOING_SALE_FORM_SCHEMA>;

// Intersecting the schema to force the refine to run even when it isn't valid
// https://github.com/colinhacks/zod/issues/479
const saleUniqueOptions = z
  .object({
    dataType: z.nativeEnum(SaleDataType),
    // dataType = collection
    collection: z
      .object({
        shopifyId: z.string().optional(),
        collectionImage: z.string().url().optional(),
      })
      .optional(),

    // dataType = product | collection | webpage
    discount: z
      .object({
        upTo: z.coerce
          .string()
          .transform((v) => (v === '' ? null : Number(v)))
          .nullable()
          .refine((v) => v === null || !isNaN(v), {
            message: 'Le discount doit être un nombre.',
          })
          .refine((v) => v === null || v >= 0, {
            message: 'Le discount doit être supérieur ou égal à 0.',
          })
          .refine((v) => v === null || v <= 100, {
            message: 'Le discount doit être inférieur ou égal à 100.',
          })
          .optional(),
        allAt: z.coerce
          .string()
          .transform((v) => (v === '' ? null : Number(v)))
          .nullable()
          .refine((v) => v === null || !isNaN(v), {
            message: 'Le discount doit être un nombre.',
          })
          .refine((v) => v === null || v >= 0, {
            message: 'Le discount doit être supérieur ou égal à 0.',
          })
          .refine((v) => v === null || v <= 100, {
            message: 'Le discount doit être inférieur ou égal à 100.',
          })
          .optional(),
      })
      .refine(
        ({ allAt, upTo }) => {
          if (!allAt && !upTo) {
            return true;
          }
          return (allAt === null) !== (upTo === null);
        },
        {
          message: 'Veuillez renseigner un seul type de discount.',
        }
      )
      .optional(),

    // dataType = webpage
    webpage: z
      .object({
        url: z.string().url('URL invalide.').optional(),
        openInApp: z.boolean().default(true),
      })
      .optional(),

    // dataType = product
    product: z
      .object({
        shopifyId: z.string().optional(),
      })
      .optional(),

    // dataType = static
    static: z
      .object({
        linkTo: z.boolean().default(true),
        deeplink: z.string().optional(),
      })
      .optional(),
  })
  .superRefine((data, ctx) => {
    if (data.dataType !== SaleDataType.COLLECTION) {
      return;
    }
    if (!data.collection?.collectionImage) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Image manquante.',
        path: ['collection', 'collectionImage'],
      });
    }
    if (!data.collection?.shopifyId) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Veuillez spécifier l'identifiant de la collection.",
        path: ['collection', 'shopifyId'],
      });
    }
  })
  .refine(
    ({ dataType, webpage }) =>
      dataType !== SaleDataType.WEBPAGE || webpage?.url !== undefined,
    {
      message: "Veuillez spécifier l'URL de la page web.",
      path: ['webpage.url'],
    }
  )
  .refine(
    ({ dataType, product }) =>
      dataType !== SaleDataType.PRODUCT || product?.shopifyId !== undefined,
    {
      message: "Veuillez spécifier l'identifiant shopify du produit.",
      path: ['product.shopifyId'],
    }
  )
  .refine(
    ({ dataType, static: staticData }) => {
      if (dataType === SaleDataType.STATIC && staticData?.linkTo) {
        return !!staticData.deeplink?.trim();
      }
      return true;
    },
    {
      message: 'Veuillez spécifier le deeplink.',
      path: ['static.deeplink'],
    }
  );

const commonSaleDates = z
  .object({
    dateStart: z.date({ message: 'Date manquante' }),
    dateStop: z.date({ message: 'Date manquante' }),
  })
  .refine((data) => data.dateStart < data.dateStop, {
    message: 'La date de début doit être antérieure à la date de fin.',
    path: ['dates'],
  });

export const commonSaleOptions = z.object({
  homepageImages: z
    .array(
      z.object({
        url: z
          .string({ message: 'Image manquante' })
          .url('URL invalide.')
          .min(1, 'URL requis.'),
      })
    )
    .nonempty('Images de la homepage manquantes.'),
  categories: z.array(z.object({ id: z.string() })),
  isDisplayedInAllSales: z.boolean(),
  isOnlyDisplayedToDevs: z.boolean(),
});

export const ONGOING_SALE_FORM_SCHEMA = z.intersection(
  saleUniqueOptions,
  commonSaleOptions.and(commonSaleDates)
);
