import { OngoingSaleFormSchema } from '../components/form/schema/OngoingSaleFormSchema';
import {
  OngoingSale,
  OngoingSaleCollection,
  OngoingSaleDTO,
  OngoingSaleProduct,
  OngoingSaleStatic,
  OngoingSaleWebpage,
  SaleDataType,
} from '../types';

export const createOngoingSaleDTOFromSchema = (
  data: OngoingSaleFormSchema,
  selectedSale: OngoingSale
) => {
  const baseDTO: OngoingSaleDTO = {
    id: selectedSale.id < 0 ? undefined : selectedSale.id,
    index: selectedSale.index || 0,
    isDisplayedInAllSales: data.isDisplayedInAllSales,
    isOnlyDisplayedToDevs: data.isOnlyDisplayedToDevs,
    // remove once legacy sales are removed
    isLegacySale: selectedSale.isLegacySale,
    dateStart: data.dateStart?.toISOString(),
    dateStop: data.dateStop?.toISOString(),
    dataType: data.dataType,
    blockType: selectedSale.blockType,
    categories: data.categories.map((category) => category.id),
    homepageImages: data.homepageImages,
    discountAllAt: data.discount?.allAt ?? undefined,
    discountUpTo: data.discount?.upTo ?? undefined,
    ...getDataTypeSpecificFields(data, selectedSale),
  };

  return baseDTO;
};

// Function to handle fields specific to each dataType
const getDataTypeSpecificFields = (
  data: OngoingSaleFormSchema,
  selectedSale: OngoingSale
): OngoingSaleCollection | OngoingSaleProduct | OngoingSaleWebpage | {} => {
  switch (data.dataType) {
    case SaleDataType.COLLECTION:
      const collection: OngoingSaleCollection = {
        id: selectedSale.collection?.id,
        name: selectedSale.collection?.name,
        shopifyId: data.collection?.shopifyId,
        collectionImage: data.collection?.collectionImage
          ? {
              url: data.collection?.collectionImage,
            }
          : undefined,
      };
      return { collection };
    case SaleDataType.PRODUCT:
      const product: OngoingSaleProduct = {
        id: selectedSale.product?.id,
        name: selectedSale.product?.name,
        shopifyId: data.product?.shopifyId,
      };
      return { product };
    case SaleDataType.WEBPAGE:
      const webpage: OngoingSaleWebpage = {
        id: selectedSale.webpage?.id,
        url: data.webpage?.url,
        openInApp: data.webpage?.openInApp ?? true,
      };
      return { webpage };
    case SaleDataType.STATIC:
      const staticData: OngoingSaleStatic = {
        id: selectedSale.static?.id,
        deeplink: data.static?.deeplink,
        linkTo: data.static?.linkTo ?? true,
      };
      return { static: staticData };
    default:
      return {};
  }
};
