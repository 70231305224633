import { useFormContext } from 'react-hook-form';
import { OngoingSale, SaleDataType } from '../../../types';
import { CollectionUniqueOptions } from './CollectionUniqueOptions';
import { ProductUniqueOptions } from './ProductUniqueOptions';
import { WebpageUniqueOptions } from './WebpageUniqueOptions';
import { OngoingSaleFormSchema } from '../../form/schema/OngoingSaleFormSchema';
import { StaticUniqueOptions } from './StaticUniqueOptions';

type SaleUniqueOptionsProps = {
  ongoingSale: OngoingSale;
};

export const SaleUniqueOptions = ({ ongoingSale }: SaleUniqueOptionsProps) => {
  const { watch } = useFormContext<OngoingSaleFormSchema>();
  const dataType = watch('dataType');

  return (
    <>
      {dataType === SaleDataType.PRODUCT && (
        <ProductUniqueOptions ongoingSale={ongoingSale} />
      )}
      {dataType === SaleDataType.WEBPAGE && <WebpageUniqueOptions />}
      {dataType === SaleDataType.COLLECTION && (
        <CollectionUniqueOptions ongoingSale={ongoingSale} />
      )}
      {dataType === SaleDataType.STATIC && <StaticUniqueOptions />}
    </>
  );
};
