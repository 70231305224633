import { UniqueIdentifier } from '@dnd-kit/core';
import {
  DND_SALE_CREATOR_ID,
  DND_ONGOING_SALE_PREVIEW_ID,
  DND_UPCOMING_SALE_PREVIEW_ID,
} from './constants';

// FRONTEND
export type SaleCreationBlock = {
  index: number;
  title: string;
  blockType: SaleBlockType;
};

export type CategoryAllSales = 'allSales';
export type CategoryUpcomingSales = 'upcomingSales';

// FRONTEND -> (UPCOMING|ONGOING)SALE

export type SaleHomepageImage = {
  url?: string;
};

export type SaleCategory = {
  id: string;
  index: number;
  title: string;
};

export enum SaleDataType {
  PRODUCT = 'PRODUCT',
  COLLECTION = 'COLLECTION',
  WEBPAGE = 'WEBPAGE',
  STATIC = 'STATIC',
}

export enum SaleBlockType {
  NORMAL = 'NORMAL',
  CAROUSEL = 'CAROUSEL',
}

export type Sale = {
  id: number;
  index: number;
  blockType: SaleBlockType;
  isOnlyDisplayedToDevs: boolean;
  isLegacySale: boolean;
  dateStart?: Date;
  dateStop?: Date;
};

export type RearrangedSales = {
  activeSale: UpcomingSale | OngoingSale;
  moved: SaleIdIndexDTO[];
};

// FRONTEND -> UPCOMING SALE

export type UpcomingSale = Sale & {
  description?: string;
  additionalImages?: UpcomingSaleAdditionnalImages;
  homepageImage?: SaleHomepageImage;
  productVendor?: string;
  lockHomepagePosition?: boolean;
};

export type UpcomingSaleAdditionnalImages = {
  leftUrl: string;
  rightUrl: string;
};

// FRONTEND -> ONGOING SALE
export type OngoingSale = Sale & {
  isDisplayedInAllSales: boolean;
  dataType: SaleDataType;
  discountAllAt?: number;
  discountUpTo?: number;
  categories: SaleCategory[];
  homepageImages: SaleHomepageImage[];
  collection?: OngoingSaleCollection;
  product?: OngoingSaleProduct;
  webpage?: OngoingSaleWebpage;
  static?: OngoingSaleStatic;
};

export type OngoingSaleStatic = {
  id?: number;
  deeplink?: string;
  linkTo: boolean;
};

export type OngoingSaleWebpage = {
  id?: number;
  url?: string;
  openInApp: boolean;
};

export type OngoingSaleProduct = {
  id?: number;
  name?: string;
  shopifyId?: string;
};

export type OngoingSaleCollection = {
  id?: number;
  name?: string;
  shopifyId?: string;
  collectionImage?: CollectionImage;
};

export type CollectionImage = {
  id?: number;
  url?: string;
};

// FRONTEND -> DRAGGABLES

export type DNDContainerType =
  | typeof DND_SALE_CREATOR_ID
  | typeof DND_ONGOING_SALE_PREVIEW_ID
  | typeof DND_UPCOMING_SALE_PREVIEW_ID;

export type DraggableUpcomingSale = {
  id: UniqueIdentifier;
  sale: UpcomingSale;
};

export type DraggableOngoingSale = {
  id: UniqueIdentifier;
  sale: OngoingSale;
};

export type DraggableCreationBlock = {
  id: UniqueIdentifier;
  creationBlock: SaleCreationBlock;
};

// BACKEND
export type DatesDTO = {
  dateStart?: string;
  dateStop?: string;
};
export type CategoriesDTO = { categories: string[] };

export type OngoingSaleDTO = Omit<
  OngoingSale,
  'id' | 'dateStart' | 'dateStop' | 'categories'
> & { id?: number } & DatesDTO &
  CategoriesDTO;

export type UpcomingSaleDTO = Omit<
  UpcomingSale,
  'id' | 'dateStart' | 'dateStop'
> & { id?: number } & DatesDTO;

export type SaleIdIndexDTO = {
  id: number;
  isLegacySale: boolean;
  index: number;
};
