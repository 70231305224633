import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../../../../shadcn/components/tooltip';
import { FormFieldInput } from '../../form/fields/FormFieldInput';
import { FormFieldSwitch } from '../../form/fields/FormFieldSwitch';
import { OngoingSaleFormSchema } from '../../form/schema/OngoingSaleFormSchema';

export const StaticUniqueOptions = () => {
  const { watch } = useFormContext<OngoingSaleFormSchema>();
  const linkTo = watch('static.linkTo');

  return (
    <Container>
      <TooltipProvider>
        <Tooltip delayDuration={100}>
          <TooltipTrigger asChild>
            <div className="w-full">
              <FormFieldSwitch
                fieldName="static.linkTo"
                label="Rediriger vers le deeplink"
                classNames={{
                  label: 'font-workSansSemiBold text-bluePrimary',
                }}
                value={linkTo ?? true}
              />
            </div>
          </TooltipTrigger>
          <TooltipContent align="end" alignOffset={-20}>
            <p>Activer redirection par deeplink</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <FormFieldInput
        type="text"
        fieldName="static.deeplink"
        placeholder="Entrez le deeplink..."
        classNames={{
          container: 'flex flex-col items-center w-full',
          label: 'font-workSansSemiBold text-bluePrimary',
          input:
            'font-workSansSemiBold border-greyPrimary  rounded-md bg-beigePrimary px-2 py-1 text-blueSecondary focus-visible:outline-blueSecondary focus-visible:ring-1',
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  width: 100%;
`;
